import React, { useContext, useState } from "react"
import { toast } from "react-toastify"
import { CartContext } from "../../contexts/Cart"
import * as styles from "./index.module.scss"
import axios from "axios"
import { StoreContext } from "../../contexts/Store"
import currencyFormat from "../../utils/currencyFormat"

const isDev = value => (process.env.NODE_ENV === "development" ? value : "")

const defaultUserState = {
  firstName: isDev("Mahesh"),
  lastName: isDev("Samudra"),
  email: isDev("mahesh@owita.lk"),
  address: isDev("No. 123, Temple Road"),
  phone: isDev("081728374"),
  city: isDev("Adelaide"),
  paymentMethod: isDev("Cash on delivery"),
}

const CheckoutForm = ({
  setSuccess = () => null,
  setOrderId = () => null,
  setMethod = () => null,
  setUserEmailNotificationError = () => null,
}) => {
  const [user, setUser] = useState(defaultUserState)

  const [progress, setProgress] = useState(false)

  const {
    cart: { items },
    actions: { clearCart },
  } = useContext(CartContext)

  const {
    store: { products },
  } = useContext(StoreContext)

  const setValue = (name, value) => {
    setMethod(0)
    setUser({
      ...user,
      [name]: value,
    })
  }

  const isInvalid =
    !user.firstName ||
    !user.lastName ||
    !user.email ||
    !user.address ||
    !user.phone ||
    !user.city ||
    !user.paymentMethod

  const submit = async e => {
    setProgress(true)
    if (e) {
      e.preventDefault()
    }
    if (
      !user.firstName ||
      !user.lastName ||
      !user.email ||
      !user.address ||
      !user.phone ||
      !user.city
    ) {
      toast.warning(`All the fields are required`)
      return
    }

    let total = 0

    const order = items.map(item => {
      const product = products.find(p => item.id === p.id) || {}
      const lineTotal = Number((item.amount * product.selling_price).toFixed(2))
      total += lineTotal
      return {
        key: `${product.name} [${currencyFormat(product.selling_price)}] x ${
          item.amount
        }`,
        value: `${currencyFormat(lineTotal)}`,
        name: product.name,
        unitPrice: product.selling_price,
        quantity: item.amount,
        total: lineTotal,
      }
    })

    const newOrderId = await axios
      .post("/api/new-order", {
        firstName: user?.firstName,
        lastName: user?.lastName,
        phone: user?.phone,
        email: user?.email,
        address: user?.address,
        city: user?.city,
        total: Number(total),
        order,
      })
      .then(res => res?.data?.orderId || null)
      .catch(e => {
        console.log(e)
        return null
      })

    if (!newOrderId) {
      setProgress(false)
      toast.error("Error Occurred. Please Try Again.")
      return
    }

    setOrderId(newOrderId)

    const fields = [
      { key: "Order ID", value: newOrderId },
      { key: "Name", value: `${user?.firstName} ${user?.lastName}` },
      { key: "Phone", value: user?.phone },
      { key: "Email", value: user?.email },
      { key: "Address", value: user?.address },
      { key: "City", value: user?.city },
      { key: "Order total", value: currencyFormat(total) },
    ]

    // Email to the user
    await axios
      .post("/api/send-mail", {
        to: [user?.email],
        replyEmail: "info@owitanatural.au",
        bodyDescription: `Hello ${
          user?.firstName || "there"
        }<br/><br/>We received your order. One of our agents will get back to you soon.`,
        subject: "New order from owitanaturals.au",
        fields,
        order,
      })
      .catch(err => {
        console.log(err)
        setUserEmailNotificationError()
      })

    // Email to the admin
    await axios
      .post("/api/send-mail", {
        replyEmail: user?.email,
        bodyDescription: `A user placed a new order from the website.`,
        subject: "New order from owitanaturals.au",
        fields,
        order,
      })
      .then(() => {
        clearCart()
        setSuccess()
      })
      .catch(err => {
        console.log(err)
        toast.error("Error Occurred. Please Try Again")
      })
  }

  return (
    <div className={"container mt-5 mt-lg-0"}>
      <h5>Contact Information</h5>
      <div className="row">
        <div className="col-sm-6">
          <Input
            label="First name *"
            value={user.firstName}
            setValue={value => setValue("firstName", value)}
          />
        </div>
        <div className="col-sm-6">
          <Input
            label="Last name *"
            value={user.lastName}
            setValue={value => setValue("lastName", value)}
          />
        </div>
        <div className="col-sm-6">
          <Input
            label="Email *"
            value={user.email}
            setValue={value => setValue("email", value)}
          />
        </div>
        <div className="col-sm-6">
          <Input
            label="Phone number"
            value={user.phone}
            setValue={value => setValue("phone", value)}
          />
        </div>
      </div>

      <h5 className="mt-3">Delivery Information</h5>
      <div className="row">
        <div className="col-sm-12">
          <Input
            label="Address"
            value={user.address}
            setValue={value => setValue("address", value)}
          />
        </div>
        <div className="col-sm-12">
          <SelectInput
            label="City"
            value={user.city}
            setValue={value => setValue("city", value)}
            options={["Adelaide"]}
          />
        </div>
        <div className="col-sm-12">
          <SelectInput
            label="Payment Method"
            value={user.paymentMethod}
            setValue={value => setValue("paymentMethod", value)}
            options={["Cash on delivery", "Bank transfer"]}
          />
        </div>

        <div className="col-12">
          <hr />
          <p>
            Payment methods: Cash on delivery / Bank transfer.
            <br />
            <br />
            You will receive more details including the Bank details with order
            confirmation email.
          </p>
          <hr />
          <button
            className={`btn btn-primary me-auto ${styles.submit_button}`}
            disabled={progress || isInvalid}
            onClick={submit}
          >
            {progress ? <Loading /> : "Place Order"}
          </button>
        </div>
      </div>
    </div>
  )
}
const Loading = () => (
  <span
    className="spinner-border spinner-border-sm text-light"
    role="status"
    aria-hidden="true"
  />
)

const Input = ({ label = "", value = "", setValue = () => null }) => {
  return (
    <div className="mb-3">
      <label htmlFor="">{label}</label>
      <input
        type="text"
        className="form-control"
        value={value}
        onChange={e => setValue(e.target.value)}
      />
    </div>
  )
}

const SelectInput = ({
  label = "",
  value = "",
  setValue = () => null,
  options = [],
}) => {
  return (
    <div className="mb-3">
      <label htmlFor="">{label}</label>
      <select
        className="form-select"
        value={value}
        onChange={e => setValue(e.target.value)}
      >
        <option value="" disabled>
          Please select
        </option>
        {options.map(option => (
          <option value={option} key={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  )
}

export default CheckoutForm
